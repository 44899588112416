@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import '../sass/mixins.scss';

.App {
    font-family: 'Roboto';
    //background-color: #c8ddff;
    background-image: url("../../public/images/background-narov.png");
    background-size: contain;
    background-repeat: repeat;
    width: auto;
    height: 100vh;

    .container {
        padding: 2% 15%;
        padding-bottom: 0;

        .back-white {
            background-color: rgba(170, 191, 249, 0.85);
            border-radius: 20px;
            padding: 20px;

            .main-block {
                display: flex;
                justify-content: center;
    
                &-text {
                    padding: 35px 0;
                    text-align: center;
                    margin-left: 5%;
    
                    .name {
                        @include adaptiv-font(25,20);
                        font-weight: 700;
                        line-height: 40px;
                    }
    
                    .address {
                        font-weight: 300;
                        line-height: 18px;
                    }
    
                    .spec {
                        font-weight: 300;
                    }
    
                    @media screen and (max-width: 450px) {
                        margin-left: 0;
                        padding: 10px 0;
                    }
                }
    
                &-image {
                    display: flex;
                    width: auto;
                    height: auto;
                    justify-content: center;
    
                    .photo {
                        width: 150px;
                        height: 150px;
                        border-radius: 20px;
                    }
                }
    
                
    
                @media screen and (max-width: 450px) {
                    display: block;
                }
            }
    
            @media screen and (max-width: 1000px) {
                padding: 3% 8%;
            }
    
            @media screen and (max-width: 800px) {
                padding: 3%;
            }
    
            .projects {
                padding: 45px 0;
                .title {
                    text-align: center;
                    @include adaptiv-font(23,20);
                    font-weight: 400;
                    margin-bottom: 10px;
                }
    
                .slides {
                    display: flex;
                    justify-content: center;
                }
            }
    
            .contacts {
                .title {
                    text-align: center;
                    @include adaptiv-font(23,20);
                    font-weight: 400;
                    margin-bottom: 10px;
                }
    
                .icons {
                    display: flex;
                    justify-content: center;
                    
                    &-link {
                        margin-right: 20px; 
    
                        &:last-child {
                            margin-right: 0;
                        }
    
                        &-item {
                            width: 50px;
                        }
                    }
                }
            }
        }
    }
}