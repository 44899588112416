.slide-image {
    height: 30%;
    width: 100%;
}

.main-slider {
    width: 60%;
}

@media screen and (max-width: 700px){
    .main-slider {
        width: 80%;
    }
}